import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Case } from '../../types/CaseRecord';
import CaseActionsMenu from './CaseActionsMenu';
import SortArrow from '../ui-elements/SortArrow';
import { getStatusColor, formatDate } from '../../utils/caseUtils';

interface CasesDesktopViewProps {
  cases: Case[];
  columnWidths: number[];
  sortConfig: { key: keyof Case; direction: 'ascending' | 'descending' };
  onRequestSort: (key: keyof Case) => void;
  onCaseDetailsClick: (caseItem: Case) => void;
  onCaseClosed: (caseId: string, newStatus: string) => void;
  onCaseOpened: (caseId: string, newStatus: string) => void;
  onAddComment: (caseItem: Case) => void;
  closingCaseId: string | null;
  openingCaseId: string | null;
  onActionStart: (caseId: string, action: 'open' | 'close') => void;
  onActionEnd: () => void;
  onColumnResize: (index: number, e: React.MouseEvent) => void;
}

const CasesDesktopView: React.FC<CasesDesktopViewProps> = ({
  cases,
  columnWidths,
  sortConfig,
  onRequestSort,
  onCaseDetailsClick,
  onCaseClosed,
  onCaseOpened,
  onAddComment,
  closingCaseId,
  openingCaseId,
  onActionStart,
  onActionEnd,
  onColumnResize,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow className="bg-cool-grey-01">
            {['Actions', 'Case Number', 'Subject', 'Status', 'JIRA Ticket', 'JIRA Status', 'Origin', 'Created Date'].map((header, index) => (
              <TableCell
                key={header}
                style={{ width: columnWidths[index] }}
                className="px-2 py-2 text-left text-xs font-medium !text-white uppercase tracking-wider cursor-pointer relative group border-r border-cool-grey-02"
                onClick={() => onRequestSort(header as keyof Case)}
              >
                <div className="flex items-center justify-between">
                  <span className="mr-2">{header}</span>
                  <SortArrow sortKey={header as keyof Case} currentSortKey={sortConfig.key} direction={sortConfig.direction} />
                </div>
                <div
                  className="absolute right-0 top-0 h-full w-2 cursor-col-resize select-none opacity-0 group-hover:opacity-100"
                  onMouseDown={(e) => onColumnResize(index, e)}
                ></div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cases.map((caseItem, index) => (
            <TableRow
              key={`${caseItem.Id || caseItem.CaseNumber || index}`}
              className={`
                ${index % 2 === 0 ? 'bg-white' : 'bg-cirrus'}
                hover:bg-cool-grey-02 transition-colors duration-200
              `}
            >
              <TableCell className="px-0 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">
                <CaseActionsMenu
                  caseItem={caseItem}
                  onCaseClosed={onCaseClosed}
                  onCaseOpened={onCaseOpened}
                  onSendComment={onAddComment}
                  closingCaseId={closingCaseId}
                  openingCaseId={openingCaseId}
                  onActionStart={onActionStart}
                  onActionEnd={onActionEnd}
                />
              </TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap text-sm font-medium text-storm border-b border-cool-grey-02">
                <span 
                  className="text-cerulean hover:underline cursor-pointer"
                  onClick={() => onCaseDetailsClick(caseItem)}
                >
                  {caseItem.CaseNumber}
                </span>
              </TableCell>
              <TableCell className="px-2 py-2 text-sm text-cool-grey-01 border-b border-cool-grey-02">
                <span 
                  className="text-cerulean hover:underline cursor-pointer"
                  onClick={() => onCaseDetailsClick(caseItem)}
                >
                  {caseItem.Subject}
                </span>
              </TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap border-b border-cool-grey-02">
                <span className={`px-1.5 py-0.5 inline-flex text-xs leading-4 font-semibold rounded-full ${getStatusColor(caseItem.Status)}`}>
                  {caseItem.Status || 'N/A'}
                </span>
              </TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.JIRA_Ticket_Key__c || 'N/A'}</TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.JIRA_Status__c || 'N/A'}</TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.Origin || 'N/A'}</TableCell>
              <TableCell className="px-2 py-2 whitespace-nowrap text-sm text-cool-grey-01 border-b border-cool-grey-02">{caseItem.CreatedDate ? formatDate(caseItem.CreatedDate) : 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CasesDesktopView;
