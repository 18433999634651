import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ContactRecord } from '../../types/ContactRecord';
import ErrorMessage from '../ui-elements/ErrorMessage';

interface ContactDetailsProps {
  contactRecord: ContactRecord | null;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contactRecord }) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <ErrorMessage message="Please log in to view contact details." />;
  }

  if (!contactRecord) {
    return <ErrorMessage message="No Salesforce details found. Please contact support@ekwis.com to set you up on this portal." />;
  }

  const renderDataTable = (dataObj: Record<string, string>) => (
    <table className="w-full">
      <tbody>
        {Object.entries(dataObj).map(([key, value]) => (
          <tr key={key} className="border-b border-cool-grey-02">
            <td className="py-2 px-4 font-semibold text-cool-grey-01">{key}</td>
            <td className="py-2 px-4 text-storm">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const contactInfo: Record<string, string> = {
    'First Name': contactRecord.FirstName || '',
    'Last Name': contactRecord.LastName || '',
    'Email': contactRecord.Email || '',
    'Phone': contactRecord.Phone || '',
    'Mobile': contactRecord.MobilePhone || '',
    'Title': contactRecord.Title || '',
    'Department': contactRecord.Department || '',
  };

  return (
    <div className="overflow-x-auto p-4">
      {renderDataTable(contactInfo)}
    </div>
  );
};

export default ContactDetails;
