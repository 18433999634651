import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Button from './Button';

interface FormActionsProps {
  onClear: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  submitText: string;
}

export function FormActions({ onClear, onSubmit, isSubmitting, submitText }: FormActionsProps) {
  return (
    <div className="flex justify-end space-x-4 mt-4">
      <Button
        onClick={onClear}
        disabled={isSubmitting}
        icon={FaTimes}
        variant="tertiary"
      >
        Clear
      </Button>
      <Button
        onClick={onSubmit}
        disabled={isSubmitting}
        variant="primary"
      >
        {isSubmitting ? 'Submitting...' : submitText}
      </Button>
    </div>
  );
}
