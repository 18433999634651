import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AccountRecord } from '../../types/AccountRecord';
import ErrorMessage from '../ui-elements/ErrorMessage';

interface AccountDetailsProps {
  accountRecord: AccountRecord | null;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ accountRecord }) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <ErrorMessage message="Please log in to view account details." />;
  }

  if (!accountRecord) {
    return <ErrorMessage message="No account details available. Please contact support@ekwis.com for assistance." />;
  }

  const renderDataTable = (dataObj: Record<string, string>) => (
    <table className="w-full">
      <tbody>
        {Object.entries(dataObj).map(([key, value]) => (
          <tr key={key} className="border-b border-cool-grey-02">
            <td className="py-2 px-4 font-semibold text-cool-grey-01">{key}</td>
            <td className="py-2 px-4 text-storm">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const accountInfo: Record<string, string> = {
    'Account Name': accountRecord.Name || '',
    'Billing Street': accountRecord.BillingStreet || '',
    'Billing City': accountRecord.BillingCity || '',
    'Billing State': accountRecord.BillingState || '',
    'Billing Postal Code': accountRecord.BillingPostalCode || '',
    'Billing Country': accountRecord.BillingCountry || '',
    'Phone': accountRecord.Phone || '',
    'Website': accountRecord.Website || '',
    'Industry': accountRecord.Industry || '',
  };

  return (
    <div className="overflow-x-auto p-4">
      {renderDataTable(accountInfo)}
    </div>
  );
};

export default AccountDetails;
