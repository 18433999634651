import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IconType } from 'react-icons';

export interface SidebarOption {
  label: string;
  path: string;
  icon: IconType;
}

interface SidebarProps {
  options: SidebarOption[];
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

const Sidebar: React.FC<SidebarProps> = ({ 
  options, 
  title, 
  isOpen, 
  onToggle,
  deviceType
}) => {
  const location = useLocation();

  const sidebarClasses = `
    bg-white shadow-md flex flex-col h-full rounded-lg
    ${isOpen ? 'w-64' : 'w-16'}
    transition-all duration-300 ease-in-out
  `;

  return (
    <div className={sidebarClasses}>
      <div className="p-4 flex items-center justify-between">
        {isOpen && <h2 className="text-xl font-bold text-storm">{title}</h2>}
        <button
          onClick={onToggle}
          className="p-2 rounded-full bg-cool-grey-02 text-storm hover:bg-cool-grey-01 transition-colors duration-200"
        >
          {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      <nav className="flex-grow overflow-y-auto">
        <ul className="px-2">
          {options.map((option) => (
            <li key={option.path} className="mb-2">
              <Link
                to={option.path}
                className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 
                  ${location.pathname === option.path
                    ? 'bg-cerulean text-white mx-1'
                    : 'text-cool-grey-01 hover:bg-cool-grey-02 hover:text-storm'
                  }`}
              >
                <option.icon className={`${isOpen ? 'mr-2' : 'mx-auto'}`} />
                {isOpen && <span>{option.label}</span>}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
