import React, { useState, createContext, useContext, ReactNode } from 'react';
import Sidebar, { SidebarOption } from '../navigation/Sidebar';
import TitleSection from '../ui-elements/TitleSection';
import { IconType } from 'react-icons';
import { useDataContext } from '../../App';
import { PopupModal } from './PopupModal';

interface PageLayoutProps {
  children: React.ReactNode;
  showSidebar?: boolean;
  sidebarOptions?: SidebarOption[];
  sidebarTitle?: string;
  pageTitle?: string;
  pageIcon?: IconType;
}

interface PopupContextType {
  openPopup: (content: ReactNode, title: string) => void;
  closePopup: () => void;
}

export const PopupContext = createContext<PopupContextType | undefined>(undefined);

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  showSidebar = false,
  sidebarOptions = [],
  sidebarTitle = 'Sections',
  pageTitle,
  pageIcon,
}) => {
  const { deviceType } = useDataContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [popupContent, setPopupContent] = useState<ReactNode | null>(null);
  const [popupTitle, setPopupTitle] = useState<string>('');

  const openPopup = (content: ReactNode, title: string) => {
    setPopupContent(content);
    setPopupTitle(title);
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const sidebarWidth = isSidebarOpen ? 'w-64' : 'w-16';

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      <div className="flex h-full max-w-full overflow-x-hidden">
        {showSidebar && (
          <div className={`fixed top-20 bottom-20 left-4 z-40 ${sidebarWidth} transition-all duration-300 ease-in-out`}>
            <Sidebar
              options={sidebarOptions}
              title={sidebarTitle}
              isOpen={isSidebarOpen}
              onToggle={toggleSidebar}
              deviceType={deviceType}
            />
          </div>
        )}
        <div className={`flex-1 ${showSidebar ? (isSidebarOpen ? 'ml-[266px]' : 'ml-[74px]') : ''} max-w-full overflow-x-auto`}>
          <div className="pt-4 px-6 space-y-4 max-w-full">
            {pageTitle && <TitleSection title={pageTitle} icon={pageIcon} />}
            <div className="space-y-4 max-w-full overflow-x-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
      {popupContent && (
        <PopupModal title={popupTitle} onClose={closePopup}>
          {popupContent}
        </PopupModal>
      )}
    </PopupContext.Provider>
  );
};

export default PageLayout;
