import React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { CasesTable } from '../components/case/CasesTable';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import PageLayout from '../components/layout/PageLayout';
import { useDataContext } from '../App';
import { FaTicket, FaUserClock, FaBuildingUser, FaUserCheck, FaUserXmark, FaBuilding } from 'react-icons/fa6';
import NoContactFound from '../components/ui-elements/NoContactFound';

const sidebarOptions: SidebarOption[] = [
  { label: 'My In Staging Tickets', path: '/tickets/contact/jira-status/In%20Staging', icon: FaUserClock },
  { label: 'Account In Staging Tickets', path: '/tickets/account/jira-status/In%20Staging', icon: FaBuildingUser },
  { label: 'My Open Tickets', path: '/tickets/contact/open', icon: FaUserCheck },
  { label: 'My Closed Tickets', path: '/tickets/contact/closed', icon: FaUserXmark },
  { label: 'Open Account Tickets', path: '/tickets/account/open', icon: FaBuilding },
  { label: 'Closed Account Tickets', path: '/tickets/account/closed', icon: FaBuilding },
];

const Tickets: React.FC = () => {
  const { contactRecord, accountRecord, loading, noContactFound } = useDataContext();

  if (loading) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (noContactFound) {
    return <NoContactFound pageTitle="Tickets" />;
  }

  return (
    <PageLayout
      showSidebar={true}
      sidebarOptions={sidebarOptions}
      sidebarTitle="Tickets"
      pageTitle="Tickets"
      pageIcon={FaTicket}
    >
      <Routes>
        <Route index element={<Navigate to="/tickets/contact/open" replace />} />
        <Route 
          path=":idType/:caseType/*" 
          element={
            contactRecord && accountRecord ? (
              <CaseTableWrapper 
                contactId={contactRecord.Id} 
                accountId={accountRecord.Id} 
              />
            ) : (
              <LoadingSpinner message="Loading user data..." />
            )
          } 
        />
      </Routes>
    </PageLayout>
  );
};

interface CaseTableWrapperProps {
  contactId?: string;
  accountId?: string;
}

const CaseTableWrapper: React.FC<CaseTableWrapperProps> = ({ contactId, accountId }) => {
  const { idType, caseType, '*': restOfPath } = useParams<{ idType: string; caseType: string; '*': string }>();

  const id = idType === 'contact' ? contactId : accountId;

  if (!id) {
    return <LoadingSpinner message="Loading user data..." />;
  }

  const jiraStatus = caseType === 'jira-status' ? restOfPath : undefined;

  return (
    <CasesTable
      caseType={caseType as 'all' | 'open' | 'closed' | 'to-test' | 'jira-status'}
      idType={idType as 'contact' | 'account'}
      id={id}
      jiraStatus={jiraStatus}
    />
  );
};

export default Tickets;
