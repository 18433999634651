import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CaseTypeCount } from '../../types/analytics';
import DashboardSummary from '../dashboard/DashboardSummary';
import TicketTrendChart from '../dashboard/TicketTrendChart';
import TicketCategoryPieChart from '../dashboard/TicketCategoryPieChart';
import { useDataContext } from '../../App';

// Define the Case and ContactRecord types if they're not available in separate files
interface Case {
  ContactId: string;
  Status: string;
  CreatedDate: string;
  Type: string;
}

interface ContactRecord {
  Id: string;
}

interface MyAnalyticsProps {
  contactRecord: ContactRecord;
  accountCases: Case[];
  showSummaryOnly: boolean;
}

const MyAnalytics: React.FC<MyAnalyticsProps> = ({ contactRecord, accountCases, showSummaryOnly }) => {
  const { deviceType } = useDataContext();

  const chartData = useMemo(() => {
    const myTickets = accountCases.filter(c => c.ContactId === contactRecord.Id);
    const myOpenTickets = myTickets.filter(c => c.Status !== 'Closed');
    const myClosedTickets = myTickets.filter(c => c.Status === 'Closed');

    // Group tickets by week and type
    const weeklyCounts: Record<string, Record<string, number>> = {};
    myTickets.forEach(ticket => {
      const createdDate = new Date(ticket.CreatedDate);
      const weekStart = new Date(createdDate.setDate(createdDate.getDate() - createdDate.getDay()));
      const weekKey = weekStart.toISOString().split('T')[0];
      const type = ticket.Type || 'Unspecified';
      
      if (!weeklyCounts[weekKey]) weeklyCounts[weekKey] = {};
      if (!weeklyCounts[weekKey][type]) weeklyCounts[weekKey][type] = 0;
      weeklyCounts[weekKey][type]++;
    });

    const weeklyTicketCounts = Object.entries(weeklyCounts)
      .map(([week, typeCounts]) => ({ week, ...typeCounts }))
      .sort((a, b) => a.week.localeCompare(b.week))
      .slice(-10); // Get the last 10 weeks

    const ticketTypes = Array.from(new Set(myTickets.map(c => c.Type || 'Unspecified')));

    const openContactTicketTypes: CaseTypeCount = {};
    const closedContactTicketTypes: CaseTypeCount = {};

    myOpenTickets.forEach(ticket => {
      const type = ticket.Type || 'Unspecified';
      openContactTicketTypes[type] = (openContactTicketTypes[type] || 0) + 1;
    });

    myClosedTickets.forEach(ticket => {
      const type = ticket.Type || 'Unspecified';
      closedContactTicketTypes[type] = (closedContactTicketTypes[type] || 0) + 1;
    });

    return {
      myOpenCount: myOpenTickets.length,
      myClosedCount: myClosedTickets.length,
      weeklyTicketCounts,
      ticketTypes,
      openContactTicketTypes,
      closedContactTicketTypes,
    };
  }, [accountCases, contactRecord]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DashboardSummary
          activeCount={chartData.myOpenCount}
          resolvedCount={chartData.myClosedCount}
          activeLabel="My Active Tickets"
          resolvedLabel="My Resolved Tickets"
        />
      </Grid>
      {!showSummaryOnly && (
        <>
          <Grid item xs={12}>
            <TicketTrendChart
              weeklyTicketCounts={chartData.weeklyTicketCounts}
              ticketTypes={chartData.ticketTypes}
              title="My Ticket Creation Trends (Last 10 Weeks)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.openContactTicketTypes}
              title="My Active Tickets by Category"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.closedContactTicketTypes}
              title="My Resolved Tickets by Category"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MyAnalytics;
