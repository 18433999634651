import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TablePagination } from '@mui/material';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import ErrorMessage from '../ui-elements/ErrorMessage';
import useCases from '../../hooks/useCases';
import { CaseDetails } from './CaseDetails';
import Toast from '../ui-elements/Toast';
import { Case } from '../../types/CaseRecord';
import AddCaseComment from './AddCaseComment';
import { PopupContext } from '../layout/PageLayout';
import { useDataContext } from '../../App';
import CasesMobileView from './CasesMobileView';
import CasesDesktopView from './CasesDesktopView';
import { useSortedCases } from '../../hooks/useSortedCases';
import { useColumnWidths } from '../../hooks/useColumnWidths';

interface CasesTableProps {
  caseType: 'all' | 'open' | 'closed' | 'to-test';
  idType: 'contact' | 'account';
  id: string;
  jiraStatus?: string;
}

export const CasesTable: React.FC<CasesTableProps> = ({ caseType, idType, id, jiraStatus }) => {
  const { isAuthenticated } = useAuth0();
  const { deviceType } = useDataContext();
  const isMobileView = deviceType === 'mobile';
  const initialPageSize = isMobileView ? 10 : 25;
  const { cases, loading: hookLoading, error, totalCases, page, setPage, pageSize, setCustomPageSize, refetch } = useCases(caseType, idType, id, jiraStatus, initialPageSize);
  const [localCases, setLocalCases] = useState<Case[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [sortConfig, setSortConfig] = useState<{ key: keyof Case; direction: 'ascending' | 'descending' }>({
    key: 'CreatedDate',
    direction: 'descending',
  });

  const [columnWidths, setColumnWidths] = useColumnWidths();
  const sortedCases = useSortedCases(localCases, sortConfig);

  const [closingCaseId, setClosingCaseId] = useState<string | null>(null);
  const [openingCaseId, setOpeningCaseId] = useState<string | null>(null);
  const [toastConfig, setToastConfig] = useState<{ title: string; message?: string; type?: 'success' | 'error' | 'info' } | null>(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const popupContext = useContext(PopupContext);

  const handleCaseDetailsClick = useCallback((caseItem: Case) => {
    if (popupContext) {
      popupContext.openPopup(
        <CaseDetails
          caseData={caseItem}
          onClose={popupContext.closePopup}
          isClosing={closingCaseId === caseItem.Id}
          isOpening={openingCaseId === caseItem.Id}
        />
      );
    }
  }, [popupContext, closingCaseId, openingCaseId]);

  const handleActionStart = useCallback((caseId: string, action: 'open' | 'close') => {
    if (action === 'open') {
      setOpeningCaseId(caseId);
    } else {
      setClosingCaseId(caseId);
    }
  }, []);

  const handleActionEnd = useCallback(() => {
    setOpeningCaseId(null);
    setClosingCaseId(null);
  }, []);

  const handleCaseClosed = useCallback((caseId: string, newStatus: string) => {
    setLocalCases((prevCases) =>
      prevCases.map((c) =>
        c.Id === caseId ? { ...c, Status: newStatus } : c
      )
    );
    setToastConfig({
      title: "Case Closed",
      message: `Case ${caseId} has been closed successfully.`,
      type: "success"
    });
  }, []);

  const handleCaseOpened = useCallback((caseId: string, newStatus: string) => {
    setLocalCases((prevCases) =>
      prevCases.map((c) =>
        c.Id === caseId ? { ...c, Status: newStatus } : c
      )
    );
    setToastConfig({
      title: "Case Reopened",
      message: `Case ${caseId} has been reopened successfully.`,
      type: "success"
    });
  }, []);

  const handleAddComment = useCallback((caseItem: Case) => {
    if (popupContext) {
      popupContext.openPopup(
        <AddCaseComment
          caseId={caseItem.Id || ''}
          caseNumber={caseItem.CaseNumber || ''}
          onCommentSent={(success) => {
            if (success) {
              setShowSuccessToast(true);
            }
            popupContext.closePopup();
          }}
          onClose={popupContext.closePopup}
        />
      );
    }
  }, [popupContext]);

  useEffect(() => {
    setIsLoading(true);
    if (!hookLoading && cases !== undefined) {
      setLocalCases(cases);
      setIsLoading(false);
    }
  }, [cases, hookLoading]);

  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, [page, pageSize, caseType, idType, id, jiraStatus, refetch]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value, 10);
    setCustomPageSize(newPageSize);
  };

  const rowsPerPageOptions = isMobileView ? [10, 25, 50] : [25, 50, 100, 200];

  if (!isAuthenticated) {
    return <ErrorMessage message="Please log in to view cases." />;
  }

  if (error) {
    return <ErrorMessage message={error.message || 'An error occurred while fetching cases.'} />;
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <LoadingSpinner message={`Loading ${caseType} ${idType} cases...`} />
        </div>
      ) : localCases.length === 0 ? (
        <p className="text-center text-gray-500 py-8">No cases found.</p>
      ) : (
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden">
                {isMobileView ? (
                  <CasesMobileView
                    cases={sortedCases}
                    onCaseDetailsClick={handleCaseDetailsClick}
                    onCaseClosed={handleCaseClosed}
                    onCaseOpened={handleCaseOpened}
                    onAddComment={handleAddComment}
                    closingCaseId={closingCaseId}
                    openingCaseId={openingCaseId}
                    onActionStart={handleActionStart}
                    onActionEnd={handleActionEnd}
                  />
                ) : (
                  <CasesDesktopView
                    cases={sortedCases}
                    columnWidths={columnWidths}
                    sortConfig={sortConfig}
                    onRequestSort={(key: keyof Case) => setSortConfig(prev => ({
                      key,
                      direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending'
                    }))}
                    onCaseDetailsClick={handleCaseDetailsClick}
                    onCaseClosed={handleCaseClosed}
                    onCaseOpened={handleCaseOpened}
                    onAddComment={handleAddComment}
                    closingCaseId={closingCaseId}
                    openingCaseId={openingCaseId}
                    onActionStart={handleActionStart}
                    onActionEnd={handleActionEnd}
                    onColumnResize={(index, e) => {
                      const startX = e.clientX;
                      const startWidth = columnWidths[index];

                      const onMouseMove = (e: MouseEvent) => {
                        const delta = e.clientX - startX;
                        setColumnWidths(prev => {
                          const newWidths = [...prev];
                          newWidths[index] = Math.max(60, startWidth + delta);
                          return newWidths;
                        });
                      };

                      const onMouseUp = () => {
                        document.removeEventListener('mousemove', onMouseMove);
                        document.removeEventListener('mouseup', onMouseUp);
                      };

                      document.addEventListener('mousemove', onMouseMove);
                      document.addEventListener('mouseup', onMouseUp);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <TablePagination
              component="div"
              count={totalCases}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
              classes={{ root: 'py-2 px-4' }}
            />
          </div>
        </div>
      )}
      {toastConfig && (
        <Toast
          title={toastConfig.title}
          message={toastConfig.message}
          type={toastConfig.type}
          onClose={() => setToastConfig(null)}
        />
      )}
      {showSuccessToast && (
        <Toast
          title="Comment Added"
          message="Your comment has been successfully added to the case."
          type="success"
          onClose={() => setShowSuccessToast(false)}
        />
      )}
    </div>
  );
};

export default CasesTable;
