import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Case } from '../../types/CaseRecord';
import CaseActionsMenu from './CaseActionsMenu';
import { getStatusColor, formatDate } from '../../utils/caseUtils';

interface CasesMobileViewProps {
  cases: Case[];
  onCaseDetailsClick: (caseItem: Case) => void;
  onCaseClosed: (caseId: string, newStatus: string) => void;
  onCaseOpened: (caseId: string, newStatus: string) => void;
  onAddComment: (caseItem: Case) => void;
  closingCaseId: string | null;
  openingCaseId: string | null;
  onActionStart: (caseId: string, action: 'open' | 'close') => void;
  onActionEnd: () => void;
}

const CasesMobileView: React.FC<CasesMobileViewProps> = ({
  cases,
  onCaseDetailsClick,
  onCaseClosed,
  onCaseOpened,
  onAddComment,
  closingCaseId,
  openingCaseId,
  onActionStart,
  onActionEnd,
}) => {
  return (
    <div className="grid gap-4 p-4">
      {cases.map((caseItem, index) => (
        <Card key={`${caseItem.Id || caseItem.CaseNumber || index}`} className="bg-white shadow-md">
          <CardContent>
            <div className="flex items-start mb-2">
              <div className="mr-2">
                <CaseActionsMenu
                  caseItem={caseItem}
                  onCaseClosed={onCaseClosed}
                  onCaseOpened={onCaseOpened}
                  onSendComment={onAddComment}
                  closingCaseId={closingCaseId}
                  openingCaseId={openingCaseId}
                  onActionStart={onActionStart}
                  onActionEnd={onActionEnd}
                />
              </div>
              <div className="flex-grow">
                <Typography variant="h6" component="div">
                  <span 
                    className="text-cerulean hover:underline cursor-pointer"
                    onClick={() => onCaseDetailsClick(caseItem)}
                  >
                    {caseItem.Subject}
                  </span>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Case Number: {' '}
                  <span 
                    className="text-cerulean hover:underline cursor-pointer"
                    onClick={() => onCaseDetailsClick(caseItem)}
                  >
                    {caseItem.CaseNumber}
                  </span>
                </Typography>
                <Typography variant="body2">
                  Status: <span className={`px-1.5 py-0.5 inline-flex text-xs leading-4 font-semibold rounded-full ${getStatusColor(caseItem.Status)}`}>
                    {caseItem.Status || 'N/A'}
                  </span>
                </Typography>
                <Typography variant="body2">
                  Created: {caseItem.CreatedDate ? formatDate(caseItem.CreatedDate) : 'N/A'}
                </Typography>
                <Typography variant="body2">
                  JIRA Ticket: {caseItem.JIRA_Ticket_Key__c || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  JIRA Status: {caseItem.JIRA_Status__c || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  Origin: {caseItem.Origin || 'N/A'}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default CasesMobileView;