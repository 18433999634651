import React, { useState } from 'react';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import AuthButton from '../components/ui-elements/AuthButton';
import PageLayout from '../components/layout/PageLayout';
import { FaUserCircle, FaLock, FaHome } from 'react-icons/fa';
import { Box, Tabs, Tab } from '@mui/material';
import { useDataContext } from '../App';
import useCases from '../hooks/useCases';
import MyAnalytics from '../components/analytics/MyAnalytics';
import AccountAnalytics from '../components/analytics/AccountAnalytics';

const Home: React.FC = () => {
  const { loading: contextLoading, contactRecord, accountRecord, deviceType } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);

  const { cases: accountCases, loading: casesLoading, error: casesError } = useCases('all', 'account', accountRecord?.Id || '', undefined, 1000);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const firstName = contactRecord?.FirstName || 'User';

  if (contextLoading || casesLoading) {
    return (
      <PageLayout>
        <LoadingSpinner message="Loading..." />
      </PageLayout>
    );
  }

  if (casesError) {
    return (
      <PageLayout>
        <div>Error loading cases: {casesError.message}</div>
      </PageLayout>
    );
  }

  if (!accountRecord) {
    return (
      <PageLayout>
        <div className="flex items-center justify-center h-full">
          <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md text-center">
            <FaUserCircle className="text-6xl text-cerulean mx-auto mb-4" />
            <h1 className="text-3xl font-bold mb-4 text-storm">Ekwis Support Portal</h1>
            <div className="w-24 h-1 bg-cerulean mx-auto mb-6"></div>
            <p className="text-cool-grey-01 mb-6">Log in to access your support cases and account information.</p>
            <div className="flex justify-center items-center mb-4">
              <FaLock className="text-cerulean mr-2" />
              <span className="text-storm font-medium">Secure Login</span>
            </div>
            <div className="flex justify-center">
              <AuthButton />
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle={`Welcome, ${firstName}`} pageIcon={FaHome}>
      <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
        {deviceType !== 'mobile' && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange} 
              aria-label="analytics tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'var(--cerulean)',
                },
              }}
              sx={{
                '& .MuiTab-root': {
                  color: 'var(--cool-grey-01)',
                  '&.Mui-selected': {
                    color: 'var(--cerulean)',
                  },
                },
              }}
            >
              <Tab label="My Analytics" />
              <Tab label="Account Analytics" />
            </Tabs>
          </Box>
        )}
        <div>
          {deviceType === 'mobile' ? (
            <MyAnalytics
              contactRecord={contactRecord}
              accountCases={accountCases}
              showSummaryOnly={true}
            />
          ) : (
            <>
              {activeTab === 0 && (
                <MyAnalytics
                  contactRecord={contactRecord}
                  accountCases={accountCases}
                  showSummaryOnly={false}
                />
              )}
              {activeTab === 1 && (
                <AccountAnalytics
                  accountCases={accountCases}
                  showSummaryOnly={false}
                />
              )}
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
