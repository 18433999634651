import React from 'react';
import { IconType } from 'react-icons';

interface TitleSectionProps {
  title: string;
  subtitle?: string;
  icon?: IconType;
}

const TitleSection: React.FC<TitleSectionProps> = ({ title, subtitle, icon: Icon }) => {
  return (
    <div className="bg-cirrus py-4 px-6 shadow-md rounded-lg mb-2.5 flex items-center">
      {Icon && <Icon className="text-storm mr-3 text-2xl" />}
      <div>
        <h1 className="text-3xl font-bold text-storm">{title}</h1>
        {subtitle && <p className="text-lg text-cool-grey-01 mt-1">{subtitle}</p>}
      </div>
    </div>
  );
};

export default TitleSection;
