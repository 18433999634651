import React from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import { useDataContext } from '../../App';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import ErrorMessage from '../ui-elements/ErrorMessage';

interface Auth0DetailsProps {
  user: User | undefined;
}

const Auth0Details: React.FC<Auth0DetailsProps> = ({ user }) => {
  const { isAuthenticated } = useAuth0();
  const { loading, error } = useDataContext();

  if (!isAuthenticated) {
    return <ErrorMessage message="Please log in to view Auth0 details." />;
  }

  if (loading) {
    return <LoadingSpinner message="Loading Auth0 details..." />;
  }

  if (error) {
    return <ErrorMessage message={`Error: ${error}`} />;
  }

  if (!user) {
    return <ErrorMessage message="No user data available" />;
  }

  const renderDataTable = (dataObj: Record<string, string>) => (
    <table className="w-full">
      <tbody>
        {Object.entries(dataObj).map(([key, value]) => (
          <tr key={key} className="border-b border-cool-grey-02">
            <td className="py-2 px-4 font-semibold text-cool-grey-01">{key}</td>
            <td className="py-2 px-4 text-storm">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const getConnection = (user: User): string => {
    const identities = user.identities || [];
    if (identities.length > 0 && identities[0].connection) {
      return identities[0].connection;
    }
    return user.sub?.split('|')[0] || 'N/A';
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'N/A' : date.toLocaleString();
  };

  const personalInfo: Record<string, string> = {
    'Name': `${user.given_name ?? ''} ${user.family_name ?? ''}`.trim() || 'N/A',
    'Email': user.email ?? 'N/A',
    'Login Provider': getConnection(user),
    'Last Login': formatDate(user.updated_at ?? ''),
  };

  return (
    <div className="overflow-x-auto">
      {renderDataTable(personalInfo)}
    </div>
  );
};

export default Auth0Details;
