import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CaseTypeCount } from '../../types/analytics';
import DashboardSummary from '../dashboard/DashboardSummary';
import TicketTrendChart from '../dashboard/TicketTrendChart';
import TicketCategoryPieChart from '../dashboard/TicketCategoryPieChart';
import { useDataContext } from '../../App';

// Define the Case type if it's not available in a separate file
interface Case {
  Status: string;
  CreatedDate: string;
  Type: string;
}

interface AccountAnalyticsProps {
  accountCases: Case[];
  showSummaryOnly: boolean;
}

const AccountAnalytics: React.FC<AccountAnalyticsProps> = ({ accountCases, showSummaryOnly }) => {
  const { deviceType } = useDataContext();

  const chartData = useMemo(() => {
    const openAccountTickets = accountCases.filter(c => c.Status !== 'Closed');
    const closedAccountTickets = accountCases.filter(c => c.Status === 'Closed');

    // Group tickets by week and type
    const weeklyCounts: Record<string, Record<string, number>> = {};
    accountCases.forEach(ticket => {
      const createdDate = new Date(ticket.CreatedDate);
      const weekStart = new Date(createdDate.setDate(createdDate.getDate() - createdDate.getDay()));
      const weekKey = weekStart.toISOString().split('T')[0];
      const type = ticket.Type || 'Unspecified';
      
      if (!weeklyCounts[weekKey]) weeklyCounts[weekKey] = {};
      if (!weeklyCounts[weekKey][type]) weeklyCounts[weekKey][type] = 0;
      weeklyCounts[weekKey][type]++;
    });

    const weeklyTicketCounts = Object.entries(weeklyCounts)
      .map(([week, typeCounts]) => ({ week, ...typeCounts }))
      .sort((a, b) => a.week.localeCompare(b.week))
      .slice(-10); // Get the last 10 weeks

    const ticketTypes = Array.from(new Set(accountCases.map(c => c.Type || 'Unspecified')));

    // Group tickets by type for each category
    const openAccountTicketTypes: CaseTypeCount = {};
    const closedAccountTicketTypes: CaseTypeCount = {};

    accountCases.forEach(ticket => {
      const type = ticket.Type || 'Unspecified';
      if (ticket.Status !== 'Closed') {
        openAccountTicketTypes[type] = (openAccountTicketTypes[type] || 0) + 1;
      } else {
        closedAccountTicketTypes[type] = (closedAccountTicketTypes[type] || 0) + 1;
      }
    });

    return {
      openAccountCount: openAccountTickets.length,
      closedAccountCount: closedAccountTickets.length,
      weeklyTicketCounts,
      ticketTypes,
      openAccountTicketTypes,
      closedAccountTicketTypes,
    };
  }, [accountCases]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DashboardSummary
          activeCount={chartData.openAccountCount}
          resolvedCount={chartData.closedAccountCount}
          activeLabel="Active Tickets"
          resolvedLabel="Resolved Tickets"
        />
      </Grid>
      {!showSummaryOnly && (
        <>
          <Grid item xs={12}>
            <TicketTrendChart
              weeklyTicketCounts={chartData.weeklyTicketCounts}
              ticketTypes={chartData.ticketTypes}
              title="Ticket Creation Trends (Last 10 Weeks)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.openAccountTicketTypes}
              title="Active Tickets by Category"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketCategoryPieChart
              data={chartData.closedAccountTicketTypes}
              title="Resolved Tickets by Category"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AccountAnalytics;
