import React from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useDataContext } from '../../App';

interface WeeklyTicketCount {
  week: string;
  [key: string]: number | string;
}

interface TicketTrendChartProps {
  weeklyTicketCounts: WeeklyTicketCount[];
  ticketTypes: string[];
  title: string;
}

const TicketTrendChart: React.FC<TicketTrendChartProps> = ({
  weeklyTicketCounts,
  ticketTypes,
  title,
}) => {
  const theme = useTheme();
  const { deviceType } = useDataContext();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const colors = ['#004BFF', '#1E2833', '#F5F5F5', '#68707C', '#AFB7C6'];

  const chartHeight = deviceType === 'mobile' ? 300 : deviceType === 'tablet' ? 350 : 400;
  const legendWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '30%' : 160;
  const chartWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '70%' : `calc(100% - ${legendWidth}px)`;

  return (
    <Paper elevation={3} className="p-6">
      <Typography variant="h6" gutterBottom className="text-storm font-semibold">
        {title}
      </Typography>
      <Box display="flex" flexDirection={deviceType === 'mobile' ? 'column' : 'row'}>
        <Box width={legendWidth} mb={deviceType === 'mobile' ? 2 : 0} pr={2}>
          {ticketTypes.map((type, index) => (
            <Box key={type} display="flex" alignItems="center" mb={1}>
              <Box
                width={10}
                height={10}
                bgcolor={colors[index % colors.length]}
                mr={1}
                flexShrink={0}
              />
              <Typography variant="body2" noWrap>{type}</Typography>
            </Box>
          ))}
        </Box>
        <Box width={chartWidth}>
          <LineChart
            xAxis={[{ 
              scaleType: 'band', 
              data: weeklyTicketCounts.map(item => formatDate(item.week)),
              label: 'Week Starting',
              tickLabelStyle: {
                angle: 0,
                textAnchor: 'middle',
                fontSize: 10,
              },
            }]}
            yAxis={[{
              tickMinStep: 1,
            }]}
            series={ticketTypes.map((type, index) => ({
              data: weeklyTicketCounts.map(item => (item[type] as number) || 0),
              label: type,
              color: colors[index % colors.length],
            }))}
            height={chartHeight}
            margin={{
              left: 50,
              bottom: 50,
              right: 20,
              top: 20,
            }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default TicketTrendChart;
