import React from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { CaseTypeCount } from '../../types/analytics';
import { useDataContext } from '../../App';

interface TicketCategoryPieChartProps {
  data: CaseTypeCount;
  title: string;
}

const TicketCategoryPieChart: React.FC<TicketCategoryPieChartProps> = ({ data, title }) => {
  const theme = useTheme();
  const { deviceType } = useDataContext();

  const colors = ['#004BFF', '#1E2833', '#F5F5F5', '#68707C', '#AFB7C6'];
  const pieData = Object.entries(data).map(([label, value], index) => ({ 
    id: index,
    value,
    label,
    color: colors[index % colors.length],
  }));

  const chartHeight = deviceType === 'mobile' ? 300 : deviceType === 'tablet' ? 325 : 350;
  const legendWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '30%' : 160;
  const chartWidth = deviceType === 'mobile' ? '100%' : deviceType === 'tablet' ? '70%' : `calc(100% - ${legendWidth}px)`;

  return (
    <Paper elevation={3} className="p-6">
      <Typography variant="h6" gutterBottom className="text-storm font-semibold">
        {title}
      </Typography>
      <Box display="flex" flexDirection={deviceType === 'mobile' ? 'column' : 'row'}>
        <Box width={legendWidth} mb={deviceType === 'mobile' ? 2 : 0} pr={2}>
          {pieData.map((item) => (
            <Box key={item.id} display="flex" alignItems="center" mb={1}>
              <Box
                width={10}
                height={10}
                bgcolor={item.color}
                mr={1}
                flexShrink={0}
              />
              <Typography variant="body2" noWrap>{item.label}</Typography>
            </Box>
          ))}
        </Box>
        <Box width={chartWidth}>
          <PieChart
            series={[
              {
                data: pieData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              },
            ]}
            height={chartHeight}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default TicketCategoryPieChart;
