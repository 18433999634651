import React from 'react';
import LoadingSpinner from '../ui-elements/LoadingSpinner';
import { PopupModal } from '../layout/PopupModal';

interface CaseDetailsProps {
  caseData: {
    Id?: string;
    Subject?: string;
    Status?: string;
    CaseNumber?: string;
    CreatedDate?: string;
    Description?: string;
    Origin?: string;
    Type?: string;
    Reason?: string;
    JIRA_Ticket_Key__c?: string;
    JIRA_Status__c?: string;
  };
  onClose: () => void;
  isClosing: boolean;
  isOpening: boolean;
}

export const CaseDetails: React.FC<CaseDetailsProps> = ({ caseData, onClose, isClosing, isOpening }) => {
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  if (isClosing || isOpening) {
    return (
      <PopupModal
        title="Case Details"
        subtitle={`Case Number: ${caseData.CaseNumber || 'N/A'}`}
        onClose={onClose}
      >
        <LoadingSpinner message={isClosing ? "Closing case..." : "Opening case..."} />
      </PopupModal>
    );
  }

  return (
    <PopupModal
      title="Case Details"
      subtitle={`Case Number: ${caseData.CaseNumber || 'N/A'}`}
      onClose={onClose}
    >
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="font-semibold text-cool-grey-01">Subject:</p>
          <p>{caseData.Subject || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">Status:</p>
          <p>{caseData.Status || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">Created Date:</p>
          <p>{formatDate(caseData.CreatedDate)}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">Origin:</p>
          <p>{caseData.Origin || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">Type:</p>
          <p>{caseData.Type || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">Reason:</p>
          <p>{caseData.Reason || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">JIRA Ticket:</p>
          <p>{caseData.JIRA_Ticket_Key__c || 'N/A'}</p>
        </div>
        <div>
          <p className="font-semibold text-cool-grey-01">JIRA Status:</p>
          <p>{caseData.JIRA_Status__c || 'N/A'}</p>
        </div>
        <div className="col-span-2">
          <p className="font-semibold text-cool-grey-01">Description:</p>
          <p className="whitespace-pre-wrap">{caseData.Description || 'N/A'}</p>
        </div>
      </div>
    </PopupModal>
  );
};
