'use client';

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import CountUp from 'react-countup';
import { TrendingUp, CheckCircle } from 'lucide-react';
import { useDataContext } from '../../App';

interface DashboardSummaryProps {
  activeCount: number;
  resolvedCount: number;
  activeLabel: string;
  resolvedLabel: string;
}

const DashboardSummary: React.FC<DashboardSummaryProps> = ({
  activeCount,
  resolvedCount,
  activeLabel,
  resolvedLabel,
}) => {
  const { deviceType } = useDataContext();

  return (
    <Paper elevation={1} className="p-6 bg-white rounded-lg">
      <Box 
        display="flex" 
        flexDirection={deviceType === 'mobile' ? 'column' : 'row'} 
        justifyContent="center"
        alignItems="center"
        gap={deviceType === 'mobile' ? 6 : deviceType === 'tablet' ? 12 : 24}
      >
        <SummaryItem
          count={activeCount}
          label={activeLabel}
          icon={<TrendingUp size={28} />}
          color="text-blue-500"
        />
        <SummaryItem
          count={resolvedCount}
          label={resolvedLabel}
          icon={<CheckCircle size={28} />}
          color="text-green-500"
        />
      </Box>
    </Paper>
  );
};

interface SummaryItemProps {
  count: number;
  label: string;
  icon: React.ReactNode;
  color: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ count, label, icon, color }) => (
  <Box className="flex items-center space-x-3">
    <Box className={`${color}`}>{icon}</Box>
    <Box>
      <Typography variant="h4" className={`font-bold ${color}`}>
        <CountUp end={count} duration={2} />
      </Typography>
      <Typography variant="body1" className="text-gray-600">{label}</Typography>
    </Box>
  </Box>
);

export default DashboardSummary;
